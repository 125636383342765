import React, { useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ResponsiveHeader } from "../ResponsiveHeader";
import MenuLinks from "./MenuLinks";
import GoogleCalendar from "../GoogleCalendar/GoogleCalendar";

const Header2 = () => {
  const refHeader = useRef();
  useLayoutEffect(() => {
    const header = document.getElementById("header2");
    header.classList.add("bg-gray/80", "py-3");
  }, []);

  return (
    <React.Fragment>
      <header
        className="fixed transition-all ease-in duration-300 top-0 left-0 w-full py-8 z-[9999] hidden lg:block"
        ref={refHeader}
        id="header2"
      >
        <div className="logoMenu w-full">
          <div className="container sm:container md:container lg:container xl:container 2xl:max-w-[100rem] mx-auto">
            <div className="logoMenuInner flex items-center justify-between relative w-full">
              <div className="logo inline-block max-w-[50%]">
                <Link to="/" title="Clarence Lee">
                  <Logo className="w-full"></Logo>
                </Link>
              </div>
              <MenuLinks></MenuLinks>
              <div className="menuRight hidden lg:flex items-center gap-8 lg:gap-12">
                <GoogleCalendar></GoogleCalendar>
              </div>
              {/* Logo */}
            </div>
            {/* Logo Menu Inner */}
          </div>
          {/* Logo Menu */}
        </div>
        {/* Header */}
      </header>
      <ResponsiveHeader></ResponsiveHeader>
    </React.Fragment>
  );
};

export default Header2;

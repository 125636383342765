export const ResponsiveMenuData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Services",
    path: "/services",
  },
  {
    title: "Shout-outs",
    path: "/shout-outs",
  },
  {
    title: "Contact",
    path: "/contact",
  },
];

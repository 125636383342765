import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar } from "../../components/BottomBar";
import { ContactInfoProfiles3 } from "../../components/ContactInfoProfiles";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";

import { ReactComponent as Trophy } from "../../lib/icons/Trophy.svg";

import ServImg1 from "../../assets/images/resources/clarence-lee-2.jpg";
import { Gallery1, Gallery2 } from "../../components/Gallery";

const AboutPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Clarence Lee - Every Man has a Story Behind"</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Every Man has a Story Behind"
        hometitle="Home"
        homeURL="/"
        currentPage="About"
      ></PageTitle>
      <section className="pageWrap py-[5rem] md:py-[5rem] lg:py-[5rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            <div className="servDetailTop gap-[1.875rem] lg:flex items-end relative w-full mb-[3.125rem] sm:mb-[4rem] md:mb-[5.3125rem]">
              <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full lg:max-w-[48.125rem]">
                <img className="w-full" src={ServImg1} alt=""></img>
              </div>
              <div className="servDetailInfoWrap flex flex-col w-full lg:w-[initial] gap-[2.8125rem]">
                <div className="servDetailInfo p-8 lg:px-16 lg:py-12 xl:px-20 xl:py-14 relative flex gap-[1.5625rem] mt-5 lg:ml-[-10.625rem] lg:min-w-[35.625rem] z-[1]">
                  <div className="before:absolute before:inset-0 before:bg-gray before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-[1.875rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[1.875rem] inset-0 w-full bg-no-repeat bg-center bg-cover z-[-1] bg-gray"></div>

                  <div className="flex flex-col gap-2">
                    <span className="text-dark font-NunitoSans text-[1rem] md:text-[1.125rem] font-semibold">
                      Clarence Lee
                    </span>
                    <h2 className="text-accent2 font-Poppins font-bold leading-[1.3] text-[1.875rem] lg:text-[2rem] xl:text-[2.5rem]">
                      Financial Services Director | Private Wealth Consultant
                    </h2>
                  </div>
                </div>
                <ul className="flex flex-col gap-5">
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <Trophy className="fill-accent h-[1rem] md:h-[1.125rem] mt-auto mb-auto"></Trophy>
                    <span>6x MDRT (2019 - 2024)</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <Trophy className="fill-accent h-[1rem] md:h-[1.125rem] mt-auto mb-auto"></Trophy>
                    <span>7x Star Club Award Qualifier (2018 - 2024)</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <Trophy className="fill-accent h-[1rem] md:h-[1.125rem] mt-auto mb-auto"></Trophy>
                    <span>
                      5x Premier Bronze Award Qualifier (2023, 2022, 2021, 2019,
                      2018)
                    </span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <Trophy className="fill-accent h-[1rem] md:h-[1.125rem] mt-auto mb-auto"></Trophy>
                    <span>Groomed 5x MDRT Members</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <Trophy className="fill-accent h-[1rem] md:h-[1.125rem] mt-auto mb-auto"></Trophy>
                    <span>TOP 4 SFSM Agency of Prudential 2022</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <Trophy className="fill-accent h-[1rem] md:h-[1.125rem] mt-auto mb-auto"></Trophy>
                    <span>TOP 10 FSM Agency of Prudential 2021</span>
                  </li>
                  <li className="font-NunitoSans text-desc2 text-[1rem] md:text-[1.125rem] font-semibold leading-normal flex gap-3">
                    <Trophy className="fill-accent h-[1rem] md:h-[1.125rem] mt-auto mb-auto"></Trophy>
                    <span>TOP 5 Rookie Agency of Prudential 2020</span>
                  </li>
                </ul>
                {/* Service Detail Information Wrap */}
              </div>
              {/* Service Detail Top */}
            </div>
            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-6 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-5 w-100 lg:col-span-4">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  How It All Started
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  In the dynamic arena of financial services, Clarence Lee
                  stands as a trailblazer, reshaping the landscape with a career
                  spanning 8 impactful years. His legacy isn't just one of
                  personal achievements but a testament to his transformative
                  leadership and commitment to excellence.
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  Serving nearly <span className="underline">300 families</span>
                  , Clarence's client-centric approach is underscored by an
                  extraordinary feat—achieving the revered Million Dollar Round
                  Table (MDRT) 6 times. However, his vision extends beyond
                  individual success; he's the guiding force behind{" "}
                  <span className="underline">5 MDRT members</span> who have
                  flourished under his mentorship within his own team.
                </p>
              </div>
              <div className="servDetailImage relative w-full lg:col-span-2 order-first md:order-first lg:order-last">
                <Gallery1></Gallery1>
              </div>
            </div>
            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-2 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailImage relative w-full lg:pr-5">
                <Gallery2></Gallery2>
              </div>
              <div className="servDetailDesc flex flex-col gap-5 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Prudential Awards
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  Prudential's rankings bear witness to Clarence's exceptional
                  prowess. Clinching the{" "}
                  <span className="underline">
                    TOP 4 Sales Financial Services Manager (SFSM)
                  </span>
                  in 2022, after securing{" "}
                  <span className="underline">TOP 10 FSM in 2021</span> and{" "}
                  <span className="underline">TOP 5 AFSM in 2020</span>,
                  Clarence has consistently elevated the standards of
                  achievement within the organization.
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  Acknowledged as a Private Wealth Consultant, Clarence
                  specializes in navigating the intricate world of investments
                  and asset allocation for the high net worth market. His
                  strategic acumen and commitment to personalized financial
                  solutions have set him apart as a trusted advisor.
                </p>
              </div>
            </div>
            <div className="servDetailDesc flex flex-col gap-6 w-full mt-[3.75rem]">
              <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                ClarenceFamilee
              </h4>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Beyond the boardroom, Clarence has forged the exclusive
                "ClarenceFamilee" community—a collective of industry
                trailblazers. This isn't just a client base; it's a testament to
                Clarence's vision of connecting the best minds, fostering
                collaboration, and propelling each member toward unprecedented
                heights.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                What sets Clarence apart is not just his impressive track record
                but his relentless pursuit of growth, unwavering dedication to
                service, and an innate eagerness to excel. In a remarkable
                display of leadership, he doesn't merely delegate; he leads by
                example. Excelling in personal sales, Clarence remains at the
                forefront of industry trends, ensuring his skills stay
                razor-sharp.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Clarence isn't just a financial director; he's a revolutionary
                force, reshaping financial leadership, and setting new standards
                of excellence in the industry.
              </p>
            </div>
            {/* Service Detail */}
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <ContactInfoProfiles3></ContactInfoProfiles3>
      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default AboutPage;

import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar } from "../../components/BottomBar";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { InstagramHighlight2 } from "../../components/InstagramFeed";

const ShoutoutsPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Clarence Lee - Shout-outs from people you know</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Shout-outs"
        hometitle="Home"
        homeURL="/"
        currentPage="Shout-outs"
      ></PageTitle>

      <section className="pageWrap pt-20 pb-[6.875rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="pageInner w-full">
            <InstagramHighlight2 />
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default ShoutoutsPage;

import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.css";
import { initGoogleAnalytics } from "./utils/GoogleAnalytics";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

initGoogleAnalytics({
  gaId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
});

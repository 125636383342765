import { FaInstagram, FaTiktok, FaYoutube, FaLinkedin } from "react-icons/fa";

export const profile = [
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full z-[1] before:z-[-1] before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#405DE6] h-[23.125rem] hover:before:h-full",
    titleColor: "text-[#405DE6]",
    profIcon: (
      <FaInstagram className="h-[4.25rem] w-[4.25rem] group-hover:brightness-[10]"></FaInstagram>
    ),
    profSubTitle: "Follow Me on",
    profTitle: "Instagram",
    profLinkCap: "Follow Now",
    profLink: "https://www.instagram.com/clarenceleeeee",
  },
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full z-[1] before:z-[-1] before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#ff0050] h-[23.125rem] hover:before:h-full",
    titleColor: "text-[#ff0050]",
    profIcon: (
      <FaTiktok className="h-[4.25rem] w-[4.25rem] group-hover:contrast-[4]"></FaTiktok>
    ),
    profSubTitle: "Debunking Financial Myths",
    profTitle: "Tiktok",
    profLinkCap: "Debunk Now",
    profLink: "https://www.tiktok.com/@clarencecontenttv",
  },
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full z-[1] before:z-[-1] before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#FF0000] h-[23.125rem] hover:before:h-full",
    titleColor: "text-[#FF0000]",
    profIcon: (
      <FaYoutube className="h-[4.25rem] w-[4.25rem] group-hover:contrast-200"></FaYoutube>
    ),
    profSubTitle: "Providing mindset hacks",
    profTitle: "Youtube",
    profLinkCap: "Find Out Now",
    profLink: "https://www.youtube.com/@ClarenceContentTV",
  },
  {
    bgColor:
      "profBox group bg-white flex justify-center items-center text-center relative w-full z-[1] before:z-[-1] before:h-2 before:absolute before:inset-x-0 before:top-0 before:bg-[#0077B5] h-[23.125rem] hover:before:h-full",
    titleColor: "text-[#0077B5]",
    profIcon: (
      <FaLinkedin className="h-[4.25rem] w-[4.25rem] group-hover:contrast-200"></FaLinkedin>
    ),
    profSubTitle: "Connect with me",
    profTitle: "LinkedIn",
    profLinkCap: "Connect Now",
    profLink: "https://www.linkedin.com/in/clarenceleeeee",
  },
];

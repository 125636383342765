import image1 from "../assets/images/resources/young-clarence-lee.jpg";
import image2 from "../assets/images/resources/school-clarence-lee.jpg";
import image3 from "../assets/images/resources/starting-clarence-lee.jpg";

export const GalleryData = [
  {
    galleryImage: image1,
    galleryImageTitle: "Young Clarence",
  },
  {
    galleryImage: image2,
    galleryImageTitle: "Clarence graduates from University",
  },
  {
    galleryImage: image3,
    galleryImageTitle: "Clarence preparing for work",
  },
];

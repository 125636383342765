import { Link } from "react-router-dom";
import { SectionTitle2 } from "../SectionTitles";
import { services } from "../../data/services";

const Services = () => {
  return (
    <section className="servicesWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
      <div className="container sm:container md:container lg:container xl:max-w-[98.125rem] mx-auto">
        <SectionTitle2
          title="The Best"
          titleInner="Services"
          subTitle="What To Expect"
          desc=""
        ></SectionTitle2>
        <div className="servicesBoxes relative w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-[1.875rem]">
            {services.map(
              (item, index) =>
                index < 4 && (
                  <Link to={`/services/#${item.id}`} title={item.servTitle}>
                    <div className="gridItem" key={index}>
                      <div className="serviceBox flex flex-wrap gap-3 flex-col justify-center relative w-full z-[1] min-h-[20rem] md:min-h-[20rem] p-[1.875rem] md:p-7 lg:p-10 xl:p-[3.125rem] text-center">
                        <div
                          className={`serviceBg before:absolute before:inset-0 before:${item.bgColor} before:rounded-[10px] before:sm:rounded-[1.25rem] before:md:rounded-[3.125rem] before:opacity-70 before:z-[1] bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] inset-0 bg-no-repeat bg-center bg-cover z-[-1] ${item.bgColor}`}
                        ></div>
                        <span className="inline-block mb-1 mx-auto">
                          {item.servIcon}
                        </span>
                        <h3
                          className={`text-[1.3rem] lg:text-[1.325rem] xl:text-[1.5rem] font-Poppins font-semibold max-w-full sm:max-w-full lg:max-w-full xl:max-w-[90%] mx-auto ${item.titleColor}`}
                        >
                          {item.servTitle}
                        </h3>
                        <p
                          className={`text-[1rem] md:text-[1.125rem] font-NunitoSans ${item.descColor}`}
                        >
                          {item.servDesc}
                        </p>
                      </div>
                    </div>
                  </Link>
                )
            )}
          </div>
          {/* Services Boxes */}
        </div>
        <div className="viewAll text-center w-full mt-10 md:mt-[3.125rem] lg:mt-[4.0625rem]">
          <Link
            className="bg-accent text-dark text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1.125rem] py-[14px] hover:bg-accent hover:border-accent hover:text-white text-center inline-block"
            to="/services"
            title="Click here to View More"
          >
            Find out More
          </Link>
        </div>
      </div>
      {/* Services Wrap */}
    </section>
  );
};

export default Services;

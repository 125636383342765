import image1 from "../assets/images/resources/prudential-awards-clarence.jpg";
import image2 from "../assets/images/resources/star-club-mdrt-clarence.jpg";
import image3 from "../assets/images/resources/top-4-sfsm agency.jpg";
import image4 from "../assets/images/resources/illuminate-clarence.jpg";
import image5 from "../assets/images/resources/premier-bronze-clarence.jpg";
import image6 from "../assets/images/resources/top-10-fsm-agency.jpg";

export const GalleryData2 = [
  {
    galleryImage: image1,
    galleryImageTitle: "Prudential Awards Clarence",
  },
  {
    galleryImage: image2,
    galleryImageTitle: "Star Club MDRT Clarence",
  },
  {
    galleryImage: image3,
    galleryImageTitle: "Top 4 SFSM Agency",
  },
  {
    galleryImage: image4,
    galleryImageTitle: "Illuminate Clarence",
  },
  {
    galleryImage: image5,
    galleryImageTitle: "Premier Bronze Clarence",
  },
  {
    galleryImage: image6,
    galleryImageTitle: "Top 10 FSM Agency",
  },
];

import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header";
import { FeaturedArea } from "../../components/FeaturedArea";
import { FunFacts2 } from "../../components/FunFacts";
import { MyWorkExperience } from "../../components/MyWorkExperience";
import { ContactInfoProfiles } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { BottomBar } from "../../components/BottomBar";
import { Services } from "../../components/Services";

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Clarence Lee</title>
      </Helmet>
      <Header></Header>
      <FeaturedArea></FeaturedArea>
      <FunFacts2></FunFacts2>
      <MyWorkExperience></MyWorkExperience>
      <Services></Services>
      <ContactInfoProfiles></ContactInfoProfiles>
      <ContactUs></ContactUs>
      <BottomBar></BottomBar>
    </Fragment>
  );
};

export default HomePage;

import React from "react";
import { Helmet } from "react-helmet";
import { FaHome } from "react-icons/fa";
import { BottomBar } from "../../components/BottomBar";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Clarence Lee - Error</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="404 Error"
        hometitle="Home"
        homeURL="/"
        currentPage="404 Error Page"
      ></PageTitle>
      <section className="pageWrap pt-[4rem] md:pt-[4.375rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="errorPage text-center relative flex flex-col items-center justify-center w-full">
            <div className="errorPageInner">
              <h2 className="text-[10rem] sm:text-[13rem] md:text-[15rem] lg:text-[18.75rem] text-stroke-10 text-stroke-dark text-fill-transparent font-Poppins font-bold leading-none -mb-6">
                404
              </h2>
              <h3 className="text-[#647c9f] font-Poppins font-medium text-[1.875rem] md:text-[2.5rem]">
                Oops page not found
              </h3>
              <Link
                className="text-dark hover:text-accent2 font-Poppins text-[1rem] md:text-[1.0625rem] font-bold uppercase inline-flex gap-2 items-center underline underline-offset-7 mt-[1.875rem] md:mt-[2.8125rem]"
                to="/"
                title="Back to Home"
              >
                <FaHome></FaHome>Back to Home
              </Link>
            </div>
            {/* Error Page */}
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default ErrorPage;

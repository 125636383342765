import { ReactComponent as HappyCustomersIcon } from "../lib/icons/HappyCustomers.svg";
import { ReactComponent as FinishedProjectsIcon } from "../lib/icons/FinishedProjects.svg";

export const funFacts = [
  {
    factIcon: (
      <HappyCustomersIcon className="w-[3.125rem] fill-dark"></HappyCustomersIcon>
    ),
    factCount: "284",
    factCap: "Lives Impacted",
  },
  {
    factIcon: (
      <FinishedProjectsIcon className="w-[3.125rem] fill-dark"></FinishedProjectsIcon>
    ),
    factCount: "674",
    factCap: "Financial Plans (2020 to 2023)",
  },
];

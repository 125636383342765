import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCards } from "swiper";
import { GalleryData2 } from "../../data/gallery-about-2";

import "swiper/css";
import "swiper/css/effect-cards";

const Gallery2 = () => {
  return (
    <React.Fragment>
      <section className="workExpWrap2 px-0 flex justify-center items-center relative w-full z-[1]">
        <div className="container mx-auto">
          <div className="swiperWrap2 relative w-full">
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[Autoplay, EffectCards]}
              slidesPerView={1}
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
            >
              {GalleryData2.map((item, i) => {
                return (
                  <SwiperSlide className="slideItem" key={i}>
                    <img
                      src={item.galleryImage}
                      alt={item.galleryImageTitle}
                    ></img>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Gallery2;

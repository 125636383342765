import { Link } from "react-router-dom";

const MenuLinks = () => {
  return (
    <nav className="relative hidden lg:block">
      <ul className="flex flex-col lg:flex-row gap-4 lg:gap-10">
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            className="group-hover:text-accent text-accent2 pr-5 relative block"
            to="/"
            title="Home"
          >
            Home
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            className="group-hover:text-accent text-accent2 pr-5 relative block"
            to="/about"
            title="About"
          >
            About
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            className="group-hover:text-accent text-accent2 pr-5 relative block"
            to="/services"
            title="Services"
          >
            Services
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            className="group-hover:text-accent text-accent2 pr-5 relative block"
            to="/shout-outs"
            title="Shout-outs"
          >
            Shout-outs
          </Link>
        </li>
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <Link
            className="group-hover:text-accent text-accent2 pr-5 relative block"
            to="/contact"
            title="Contact"
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenuLinks;

import { SectionTitleLight } from "../SectionTitles";
import React from "react";
import Calculator from "../Calculator/Calculator";

const MyWorkExperience = () => {
  return (
    <section className="workExpWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem relative w-full">
      <span className="bg-accent absolute right-0 bottom-[-.75rem] h-6 w-1/2"></span>
      <div className="fixedBg bg-center bg-cover bg-no-repeat before:absolute before:z-[1] before:inset-0 before:opacity-90 before:bg-dark bg-dark bg-blend-color-dodge xl:rounded-tl-[20rem] lg:rounded-tl-[18rem] md:rounded-tl-[15rem] sm:rounded-tl-[10rem] rounded-tl-0 overflow-hidden"></div>
      <div className="container sm:container md:container lg:container xl:max-w-[86.875rem] mx-auto">
        <SectionTitleLight
          title="Are you on the right track?"
          titleInner=""
          // desc=""
          // desc2=""
        ></SectionTitleLight>
        <div className="sliderWrap w-full max-w-full relative lg:mt-0">
          <Calculator />
        </div>
      </div>
      {/* Work Experience Wrap */}
    </section>
  );
};

export default MyWorkExperience;

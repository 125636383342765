import antithief from "../assets/images/resources/highlights-anti-thief.png";
import clarencefamilee from "../assets/images/resources/highlights-clarencefamilee.png";
import clutch from "../assets/images/resources/highlights-clutch.png";
import testimonials from "../assets/images/resources/highlights-testimonials.png";
import travelbag from "../assets/images/resources/highlights-travel-bag.png";

export const instagramHighlightsData = [
  {
    highlightImage: clarencefamilee,
    highlightTitle: "ClarenceFamilee",
    highlightUrl:
      "https://www.instagram.com/stories/highlights/17912013175526961/",
  },
  {
    highlightImage: antithief,
    highlightTitle: "CF8thAnniversary",
    highlightUrl:
      "https://www.instagram.com/stories/highlights/18244938700175260/",
  },
  {
    highlightImage: travelbag,
    highlightTitle: "CF7thAnniversary",
    highlightUrl:
      "https://www.instagram.com/stories/highlights/17956075823283521/",
  },
  {
    highlightImage: clutch,
    highlightTitle: "CF6thAnniversary",
    highlightUrl:
      "https://www.instagram.com/stories/highlights/18264093436048022/",
  },
  {
    highlightImage: testimonials,
    highlightTitle: "Testimonials",
    highlightUrl:
      "https://www.instagram.com/stories/highlights/17883640606498310/",
  },
];

import React from "react";
import ReactGA from "react-ga4";
import { SectionTitle3 } from "../SectionTitles";
import overSavings from "../../assets/images/resources/saving-over.png";
import underSavings from "../../assets/images/resources/saving-under.png";
import { GoogleCalendar } from "../GoogleCalendar";
import ApexCharts from "apexcharts";

function CalculatedResults(props) {
  let savingState = "";
  let resultTitle = "Error";
  let resultSubTitle = "";
  let resultDescription1 = "";
  let resultDescription2 = "";
  let resultStatus = false;
  let errorStatus = false;
  const calculatedResult = props.calculatedResult;

  if (calculatedResult.length === 0) {
    resultStatus = false;
  }

  if (calculatedResult.length !== 0) {
    if (calculatedResult.error) {
      errorStatus = true;
      return (
        <section className="eduAndWorkWrap py-[3rem] sm:py-[3rem] md:py-[3rem] relative w-full mt-0 md:mt-[1.875rem]">
          <div className="fixedBg bg-gray bg-blend-multiply xl:rounded-[2rem] lg:rounded-[1rem] md:rounded-[1rem] sm:rounded-[1rem] rounded-[1rem] overflow-hidden"></div>
          <div className="container sm:container md:container lg:container xl:container mx-auto">
            <SectionTitle3
              title="Error"
              titleInner=""
              desc="Please try again."
            ></SectionTitle3>
            <p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1 text-center">
              Please ensure that Gross Income is more than 0 and Estimated
              Savings cannot be over 80% from Gross Income. For disclaimers,
              please reset.
            </p>
          </div>
        </section>
      );
    }

    resultStatus = true;

    if (
      Number(calculatedResult.targetSavings) >=
      Number(calculatedResult.longTermSavings)
    ) {
      savingState = "over";
      resultTitle = "Over Saving";
      resultSubTitle =
        "Saving more than you need also means that you have more opportunities for investing or grow your money.";
      resultDescription1 =
        "Congratulations! You are saving more than you should! Now, let's optimize your financial position by exploring opportunities in fixed income instruments, and also in investments to maximize the potential of your funds!";
      resultDescription2 =
        "To understand better, schedule an appointment for an in-depth analysis to craft out a tailored strategy just for you!";
    }

    if (
      Number(calculatedResult.targetSavings) <=
      Number(calculatedResult.longTermSavings)
    ) {
      savingState = "under";
      resultTitle = "Under Saving";
      resultSubTitle = "Are you saving enough for your future?";
      resultDescription1 =
        "Oh no! You might be spending way out of your means! How can we improve that better?";
      resultDescription2 =
        "To understand better, schedule an appointment for an in-depth analysis to craft out a tailored strategy just for you!";
    }

    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.event({
        category: "Calculator",
        action: "Calculated",
        label: "Calculated Result - " + resultTitle,
      });
    }

    let options = {
      series: [
        Number(calculatedResult.dayToDay),
        Number(calculatedResult.insurance),
        Number(calculatedResult.longTermSavings),
        Number(calculatedResult.shortTermSavings),
        Number(calculatedResult.fun),
      ],
      chart: {
        width: "100%",
        type: "pie",
      },
      theme: {
        palette: "palette3",
      },
      labels: [
        "Day To Day",
        "Insurance",
        "Long Term Savings",
        "Short Term Savings",
        "Fun",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    setTimeout(function () {
      let chart = new ApexCharts(document.querySelector("#chart"), options);
      try {
        chart.destroy();
      } catch {}
      chart = new ApexCharts(document.querySelector("#chart"), options);
      chart.render();
    }, 500);
  }

  return (
    <section className="eduAndWorkWrap py-[3rem] sm:py-[3rem] md:py-[3rem] relative w-full mt-0 md:mt-[1.875rem]">
      <div className="fixedBg bg-gray bg-blend-multiply xl:rounded-[2rem] lg:rounded-[1rem] md:rounded-[1rem] sm:rounded-[1rem] rounded-[1rem] overflow-hidden"></div>
      {resultStatus && !errorStatus ? (
        <div className="container sm:container md:container lg:container xl:container mx-auto">
          <SectionTitle3
            title="Results"
            titleInner=""
            desc="Allocations is based on a generic planning and serves as a guide only."
          ></SectionTitle3>
          <div className="eduAndWorkInner relative w-full">
            <div className="grid lg:gap-[1.875rem] gap-[3.125rem] md:grid-cols-1 lg:grid-cols-2 grid-cols-1">
              <div className="gridItem my-auto">
                <div id="chart"></div>
              </div>
              <div className="gridItem">
                <div className="eduWrap relative w-full">
                  <div className="eduList relative flex flex-col gap-[10px] w-full">
                    {savingState === "over" ? (
                      <img
                        src={overSavings}
                        className="w-[50%] m-auto"
                        alt="over-savings"
                      ></img>
                    ) : (
                      <img
                        className="w-[50%] m-auto"
                        src={underSavings}
                        alt="under-savings"
                      ></img>
                    )}

                    <h3 className="text-accent2 text-[1.375rem] font-Poppins font-bold text-center">
                      {resultTitle}
                    </h3>
                    <p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1 text-center">
                      {resultSubTitle}
                    </p>
                    <p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
                      <span className="font-bold">Take Home per Month: </span>$
                      {calculatedResult.netIncome}
                    </p>
                    <p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
                      <span className="font-bold">
                        Current Savings per Month:
                      </span>{" "}
                      ${calculatedResult.targetSavings}
                    </p>
                    <p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
                      {resultDescription1}
                    </p>
                    <p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1">
                      {resultDescription2}
                    </p>
                    <GoogleCalendar></GoogleCalendar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container sm:container md:container lg:container xl:container mx-auto">
          <SectionTitle3
            title="Read before usage"
            titleInner=""
            desc=""
          ></SectionTitle3>
          <p className="text-desc2 text-[1rem] md:text-[1.25rem] sm:text-[1.225rem] mt-1 text-center">
            This calculation is based in Singapore context. No data is
            collected. Note: Estimated Savings cannot be over 80% of Gross
            Income.
          </p>
        </div>
      )}
    </section>
  );
}
export default CalculatedResults;

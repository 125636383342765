import { BrowserRouter, Routes, Route } from "react-router-dom";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import { ContactPage } from "../pages/ContactPage";
import { ErrorPage } from "../pages/ErrorPage";
import { HomePage } from "../pages/HomePage";
import { ShoutoutsPage } from "../pages/ShoutoutsPage";
import { AboutPage } from "../pages/AboutPage";
import { ServicesPage } from "../pages/ServicesPage";

const RouterLinks = () => {
  return (
    <>
      <BrowserRouter>
        <SmoothScroll></SmoothScroll>
        <Routes>
          <Route path="/" element={<HomePage></HomePage>} />
          <Route path="/about" element={<AboutPage></AboutPage>} />
          <Route path="/services" element={<ServicesPage></ServicesPage>} />
          <Route path="/shout-outs" element={<ShoutoutsPage></ShoutoutsPage>} />
          <Route path="/contact" element={<ContactPage></ContactPage>} />
          <Route path="/404" element={<ErrorPage></ErrorPage>} />
          <Route path="*" element={<ErrorPage></ErrorPage>} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default RouterLinks;

import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import CalculatedResults from "./CalculatedResults";

function Calculator() {
  const [calculatedResult, setCalculatedResult] = useState([]);
  const [grossIncome, setGrossIncome] = useState(0);
  const [targetSavings, setTargetSavings] = useState(0);

  function handleGrossIncomeChange(value) {
    if (value !== 0) {
      setGrossIncome(value);
    }
  }

  function handleTargeSavingsChange(value) {
    if (value !== 0) {
      setTargetSavings(value);
    }
  }

  function handleCalculatedResult() {
    let userResult = {};

    let netIncome = 0;

    const takeHomePercentage = 0.8;
    const dayToDayPercentage = 0.45;
    const insurancePercentage = 0.15;
    const longTermSavingsPercentage = 0.2;
    const shortTermSavingsPercentage = 0.1;
    const funPercentage = 0.1;

    netIncome = Math.round(Number(grossIncome) * takeHomePercentage).toFixed(2);

    if (
      (Number(grossIncome) <= 0 && Number(targetSavings) <= 0) ||
      Number(targetSavings) >= Number(netIncome)
    ) {
      userResult = {
        error: true,
      };
    }

    if (!userResult.error) {
      userResult = {
        grossIncome: Math.round(Number(grossIncome)).toFixed(2),
        targetSavings: Math.round(Number(targetSavings)).toFixed(2),
        netIncome: netIncome,
        dayToDay: Math.round(netIncome * dayToDayPercentage).toFixed(2),
        insurance: Math.round(netIncome * insurancePercentage).toFixed(2),
        longTermSavings: Math.round(
          netIncome * longTermSavingsPercentage
        ).toFixed(2),
        shortTermSavings: Math.round(
          netIncome * shortTermSavingsPercentage
        ).toFixed(2),
        fun: Math.round(netIncome * funPercentage).toFixed(2),
      };
    }

    setCalculatedResult(userResult);

    setGrossIncome(0);
    setTargetSavings(0);
  }

  function handleReset() {
    setCalculatedResult("");

    setGrossIncome(0);
    setTargetSavings(0);
  }

  return (
    <div className="calculatorWrap relative w-full">
      <div className="grid gap-[2rem] xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div className="fieldBox w-full">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Gross Income per Month
          </label>
          <CurrencyInput
            intlConfig={{ locale: "en-US", currency: "SGD" }}
            id="income"
            name="income"
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
            placeholder="Gross Income"
            defaultValue={0}
            value={grossIncome}
            decimalsLimit={2}
            onValueChange={(value, _) => handleGrossIncomeChange(value)}
          />
        </div>
        <div className="fieldBox w-full">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Estimated Savings per Month
          </label>
          <CurrencyInput
            intlConfig={{ locale: "en-US", currency: "SGD" }}
            id="savings"
            name="savings"
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
            placeholder="Estimated Savings Per Month"
            defaultValue={0}
            value={targetSavings}
            decimalsLimit={2}
            onValueChange={(value, _) => handleTargeSavingsChange(value)}
          />
        </div>
      </div>
      <div className="fieldbtn mt-6 md:mt-[1.875rem] w-full col-span-2">
        <button
          className="bg-accent text-dark text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1.125rem] py-[14px] hover:bg-accent hover:border-accent hover:text-white text-center inline-block"
          type="button"
          onClick={handleCalculatedResult}
        >
          Calculate
        </button>
        <button
          className="ml-6 bg-accent text-dark text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1.125rem] py-[14px] hover:bg-accent hover:border-accent hover:text-white text-center inline-block"
          type="button"
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
      <section className="eduAndWorkWrap py-[3rem] sm:py-[3rem] md:py-[3rem] relative w-full mt-0 md:mt-[1.875rem]">
        <CalculatedResults calculatedResult={calculatedResult} />
      </section>
    </div>
  );
}
export default Calculator;

import { useEffect, useState } from "react";
import RouterLinks from "./router/Routes";
import { PushSpinner } from "react-spinners-kit";
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="app">
      {loading ? (
        <div className="pageLoader fixed justify-center items-center inset-0 flex">
          <PushSpinner
            className="bg-accent"
            size={60}
            color="#e4d38d"
          ></PushSpinner>
        </div>
      ) : (
        <RouterLinks></RouterLinks>
      )}
    </div>
  );
}

export default App;

import ReactGA from "react-ga4";

export function initGoogleAnalytics(opts) {
  const { gaId, loadDelay = 3500 } = opts;

  if (!gaId) {
    return;
  }

  function onEvent(event) {
    initGTM(opts);
    event.currentTarget.removeEventListener(event.type, onEvent);
  }

  document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
      initGTM(opts);
    }, loadDelay);
  });
  document.addEventListener("scroll", onEvent);
  document.addEventListener("mousemove", onEvent);
  document.addEventListener("touchstart", onEvent);
}

function initGTM(opts) {
  ReactGA.initialize(opts.gaId);
}

import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar } from "../../components/BottomBar";
import { ContactInfoProfiles3 } from "../../components/ContactInfoProfiles";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";

const ServicesPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Clarence Lee - Services</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Services"
        hometitle="Home"
        homeURL="/"
        currentPage="Services"
      ></PageTitle>
      <section
        id="wealth-protection"
        className="freelanceWorkWrap w-full relative z-[1] scroll-m-[10rem]"
      >
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
          <div className="freelanceWork mt-[3.75rem] p-[1rem] sm:p-[2rem] md:px-[2rem] md:pl-[3.75rem] xl:pl-[10.625rem] relative w-full">
            <div className="bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-l-[3.125rem] inset-0 w-full md:w-screen z-[-1] bg-dark"></div>
            <div className="freelanceInfo w-full">
              <h2 className="text-white font-Poppins font-bold text-[1.75rem] sm:text-[2.3rem] md:text-[2.3rem] lg:text-[3.125rem]">
                Wealth Protection/Insurance Planning
              </h2>
            </div>
          </div>
          <div className="servDetailItem w-full mt-[3.75rem]">
            <div className="servDetailDesc flex flex-col gap-5 w-100 lg:col-span-4">
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Welcome to a world of proactive financial security, where
                strategic insurance planning serves as your fortress against
                life's uncertainties.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                My comprehensive services blend expert analysis with meticulous
                risk assessments, bespoke insurance solutions, ongoing
                optimization, and seamless integration with estate planning, and
                provide ongoing guidance. Benefits include financial protection,
                risk management, peace of mind, tax efficiency, and alignment
                with long-term financial goals.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Let my expertise in insurance planning pave the way for a
                brighter tomorrow, where risks are managed, goals are protected,
                and dreams are realized.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Partner with me for insurance planning that's as unique as you
                are. Your tomorrow deserves the best protection today!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="wealth-accumulation"
        className="freelanceWorkWrap w-full relative z-[1] scroll-m-[10rem]"
      >
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
          <div className="freelanceWork mt-[3.75rem] p-[1rem] sm:p-[2rem] md:px-[2rem] md:pr-[3.75rem] xl:pr-[10.625rem] relative w-full">
            <div className="bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-r-[3.125rem] inset-0 md:left-auto w-full md:w-screen z-[-1] bg-dark"></div>
            <div className="freelanceInfo w-full">
              <h2 className="text-white font-Poppins font-bold text-[1.75rem] sm:text-[2.3rem] md:text-[2.3rem] lg:text-[3.125rem]">
                Wealth Accumulation/Grow your Wealth
              </h2>
            </div>
          </div>
          <div className="servDetailItem w-full mt-[3.75rem]">
            <div className="servDetailDesc flex flex-col gap-5 w-100 lg:col-span-4">
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Capital Appreciation Investment Strategies, Passive Income
                Strategies, Capital Preservation strategies, you name it,
                Clarence can do it!
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Discover Your Path to Financial Freedom with Wealth Accumulation
                Services, and the best part, is the core area of expertise that
                Clarence is the most well versed in!
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Clarence has been a investor himself for the past 8 years, and
                with his knowledge and insights in investment, he brings a
                wealth of experience to the table, helping clients navigate the
                complex world of investments with confidence and success.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                He is experienced in various investment vehicles, provides
                client in strategic decision making, Risk Management expertise,
                and most importantly, the center of all his services, a
                client-centric approach.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                With his continuous learning and adaptation to the ever changing
                investment landscape, this proactive approach enables him to
                anticipate market shifts, seize new opportunities, and navigate
                challenges effectively on behalf of his clients.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                He is committed to helping clients achieve their financial
                objectives with prudence and foresight. His track record of
                success, coupled with a client-centric approach, makes me a
                trusted partner for individuals and organizations seeking
                knowledgeable and reliable investment advice in today's dynamic
                and competitive markets.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="estate-legacy-planning"
        className="freelanceWorkWrap w-full relative z-[1] scroll-m-[10rem]"
      >
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
          <div className="freelanceWork mt-[3.75rem] p-[1rem] sm:p-[2rem] md:px-[2rem] md:pl-[3.75rem] xl:pl-[10.625rem] relative w-full">
            <div className="bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-l-[3.125rem] inset-0 w-full md:w-screen z-[-1] bg-dark"></div>
            <div className="freelanceInfo w-full">
              <h2 className="text-white font-Poppins font-bold text-[1.75rem] sm:text-[2.3rem] md:text-[2.3rem] lg:text-[3.125rem]">
                Estate/Legacy Planning
              </h2>
            </div>
          </div>
          <div className="servDetailItem w-full mt-[3.75rem]">
            <div className="servDetailDesc flex flex-col gap-5 w-100 lg:col-span-4">
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Having too many assets? Have no idea how to properly distribute
                them according to your wishes after you are gone?
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Congratulations! You have found the perfect guy for help!
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Crafting a lasting legacy requires more than just accumulating
                wealth—it demands meticulous planning and strategic foresight.
                With Clarence's vast amount of experience and knowledge in this
                area, he is able to combine his legal acumens and financial
                expertise, to tailor each element of your estate plan to reflect
                your unique storyline and ensures a smooth transition to your
                loved ones.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                As you work with Clarence in crafting your legacy, he pays
                careful attention to the your tailored narrative, predicting and
                protecting against adversities, tax optimization wealth
                preservations, seamless succession plotlines, and finally, a
                business continuity masterplans.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Clarence continues to perfect your legacy planning through
                regular reviews, ensuring continued relevance and effectiveness,
                and the ultimate goal, is to cultivate harmony amidst
                transitions.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                In conclusion, his estate planning services combine legal
                precision, financial acumen, and a profound respect for your
                legacy aspirations. Let him guide you in crafting a legacy that
                transcends time—a testament to your values, wisdom, and enduring
                impact on future generations.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="business-planning"
        className="freelanceWorkWrap w-full relative z-[1] mb-[10rem] scroll-m-[10rem]"
      >
        <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
          <div className="freelanceWork mt-[3.75rem] p-[1rem] sm:p-[2rem] md:px-[2rem] md:pr-[3.75rem] xl:pr-[10.625rem] relative w-full">
            <div className="bg-blend-multiply absolute rounded-[10px] sm:rounded-[1.25rem] md:rounded-r-[3.125rem] inset-0 md:left-auto w-full md:w-screen z-[-1] bg-dark"></div>
            <div className="freelanceInfo w-full">
              <h2 className="text-white font-Poppins font-bold text-[1.75rem] sm:text-[2.3rem] md:text-[2.3rem] lg:text-[3.125rem]">
                Business Planning
              </h2>
            </div>
          </div>
          <div className="servDetailItem w-full mt-[3.75rem]">
            <div className="servDetailDesc flex flex-col gap-5 w-100 lg:col-span-4">
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                "A company's employees are its greatest asset and your people
                are your product" - Richard Branson
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                "People are your most valuable asset. Only people can be made to
                appreciate in value" - Stephen R. Covey
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Are you a business owner? Or are you a owner of multiple
                businesses?
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Keen to further scale your business? How can you increase
                employee satisfactions?
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                You came to the right place!
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                In the dynamic realm of modern business, strategic growth is
                intricately tied to holistic planning, including employee
                benefits, corporate business planning professionals, facilitated
                by seasoned professionals, encompass strategy planning,
                financial analysis, market research, operational optimization,
                risk management, technology integration, and crucially, the
                strategic planning of employee benefits.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                With the vast amount of requirements above, Clarence will be
                able off load 3 key areas for you!
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Namely : Financial Analysis, Risk Management, Employee benefits
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                Through a comprehensive corporate business plan, it can drive
                strategic growth while emphasizing the importance of the
                employee well-being through the thoughtful benefits planning,
                nurturing a motivated and engaged workforce. This integrated
                approach ensures long-term success and resilience in today's
                competitive business environment!
              </p>
            </div>
          </div>
        </div>
      </section>

      <ContactInfoProfiles3></ContactInfoProfiles3>
      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default ServicesPage;

import { ReactComponent as UmbrellaIcon } from "../lib/icons/umbrella-solid.svg";
import { ReactComponent as IndustryIcon } from "../lib/icons/industry-solid.svg";
import { ReactComponent as HouseCheckIcon } from "../lib/icons/house-circle-check-solid.svg";
import { ReactComponent as VaultIcon } from "../lib/icons/vault-solid.svg";

export const services = [
  {
    id: "wealth-protection",
    bgColor: "bg-[#d4e4f0]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: (
      <UmbrellaIcon className="h-[4.25rem] w-[4.25rem] fill-accent2"></UmbrellaIcon>
    ),
    servTitle: "Wealth Protection/Insurance Planning",
    servDesc:
      "Welcome to a world of proactive financial security, where strategic insurance planning serves as your fortress against life's uncertainties.",
  },
  {
    id: "wealth-accumulation",
    bgColor: "bg-[#d4e4f0]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: (
      <VaultIcon className="h-[4.25rem] w-[4.25rem] fill-accent2"></VaultIcon>
    ),
    servTitle: "Wealth Accumulation/Grow your Wealth",
    servDesc:
      "Capital Appreciation Investment Strategies, Passive Income Strategies, Capital Preservation strategies, you name it, Clarence can do it!",
  },
  {
    id: "estate-legacy-planning",
    bgColor: "bg-[#d4e4f0]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: (
      <HouseCheckIcon className="h-[4.25rem] w-[4.25rem] fill-accent2"></HouseCheckIcon>
    ),
    servTitle: "Estate/Legacy Planning",
    servDesc:
      "Having too many assets? Have no idea how to properly distribute them according to your wishes after you are gone?",
  },
  {
    id: "business-planning",
    bgColor: "bg-[#d4e4f0]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: (
      <IndustryIcon className="h-[4.25rem] w-[4.25rem] fill-accent2"></IndustryIcon>
    ),
    servTitle: "Business Planning",
    servDesc:
      "Are you a business owner? Or are you a owner of multiple businesses?",
  },
];

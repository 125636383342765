import { Link } from "react-router-dom";
import { bottomMenu } from "../../data/bottom-links";

const BottomBar = () => {
  return (
    <div className="bottomBarWrap relative w-full py-8">
      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
        <div className="bottomBar gap-4 flex flex-col justify-center items-center text-center">
          <p className="text-desc2 text-[1rem] font-NunitoSans">
            &copy; 2024 All Right Reserved.
          </p>
          <p className="text-desc2 text-[1rem] font-NunitoSans">
            This website is prepared by Individual PACS rep: Clarence Lee
            representing Prudential Assurance Company Singapore. Opinions
            expressed solely in Clarence Lee's personal capacity and neither
            express the views or opinions of Prudential nor represent any
            professional advice in Clarence Lee's capacity as a PACS
            representative. Approval Code: AT25Jun24GU47
          </p>
          <ul className="flex relative divide-x divide-solid">
            {bottomMenu.map(
              (item, index) =>
                index < 2 && (
                  <li
                    className="text-desc2 text-[1rem] font-NunitoSans font-semibold px-6 leading-none"
                    key={index}
                  >
                    {item.bottomMenuCap}:{" "}
                    <Link
                      className="hover:text-accent"
                      to={item.bottomMenuLink}
                      target="_blank"
                      rel="noreferrer"
                      title={item.bottomMenuCap}
                    >
                      {item.bottomMenuLink}
                    </Link>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;

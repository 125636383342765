import React from "react";
import ReactGA from "react-ga4";

export default function Modal() {
  const [showModal, setShowModal] = React.useState(false);
  const googlecalendar_src = process.env.REACT_APP_GOOGLE_CALENDAR;

  function handleGAEvent() {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.event({
        category: "Appointment",
        action: "Clicks",
        label: "Attempted to book Appointment",
      });
    }
  }

  return (
    <>
      <button
        className="bg-accent border-accent border-solid border-2 border-current text-dark text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1rem] py-[10px] hover:bg-accent hover:border-accent hover:text-white text-center inline-block"
        type="button"
        onClick={() => {
          setShowModal(true);
          handleGAEvent();
        }}
      >
        Schedule Appointment
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none w-full">
            <div className="relative my-6 mx-12 w-full">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-accent2 font-Poppins font-semibold text-[1.75rem] md:text-[2.25rem] max-w-full md:max-w-[80%] leading-[1.3]">
                    Schedule an Appointment
                  </h3>
                  <button
                    className="p-1 ml-auto my-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent m-auto text-black h-10 w-10 text-3xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <iframe
                    src={googlecalendar_src}
                    width="100%"
                    height="660"
                    title="google-calendar"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

import React from "react";
import { instagramHighlightsData } from "../../data/instagram-highlights";

import "swiper/css";
import "swiper/css/pagination";

const InstagramHighlights2 = () => {
  return (
    <React.Fragment>
      <section className="workExpWrap2 px-0 py-[3.5rem] md:py-[4.5rem] lg:-mt-[4.25rem] flex justify-center items-center relative w-full z-[1]">
        <div className="container mx-auto">
          <h2 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
            What the client's have to say!
          </h2>
          <div className="flex flex-wrap relative w-full py-3">
            {instagramHighlightsData.map((item) => {
              return (
                <div className="w-full md:w-1/3 p-2">
                  <div className="p-4 rounded text-center">
                    <a
                      href={item.highlightUrl}
                      className="m-auto"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        class="rounded-xl mb-5"
                        src={item.highlightImage}
                        alt={item.highlightTitle}
                      ></img>
                    </a>
                    <span>{item.highlightTitle}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default InstagramHighlights2;

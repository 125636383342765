import React, { useState } from "react";
import ReactGA from "react-ga4";
import axios from "axios";

function ContactForm() {
  const [contactName, setName] = useState("");
  const [contactEmail, setEmail] = useState("");
  const [contactMessage, setMessage] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const [mailError, setMailError] = useState(null);

  const successMessage = "Thank you for contacting me.";
  const errorMessage = "Error in sending email, please try again.";

  const recaptcha_site = process.env.REACT_APP_RECAPTCHAV3_SITE;
  const loadDelay = 3500;

  React.useEffect(() => {
    setTimeout(() => {
      const scriptTag = document.createElement("script");
      scriptTag.src = `https://www.google.com/recaptcha/api.js?render=${recaptcha_site}`;
      document.body.appendChild(scriptTag);
    }, loadDelay);
  }, [recaptcha_site, loadDelay]);

  function handleContactNameChange(value) {
    setName(value);
  }

  function handleContactEmailChange(value) {
    setEmail(value);
  }

  function handleContactMessageChange(value) {
    setMessage(value);
  }

  function handleFormSubmit(e) {
    e.preventDefault();

    if (!window.grecaptcha) {
      return;
    }

    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(recaptcha_site, {
        action: "submit",
      });
      if (!token) {
        return;
      }

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/script/enquiry.php`,
        headers: { "content-type": "application/json" },
        data: {
          lastName: contactName,
          email: contactEmail,
          msg: contactMessage,
          verificationToken: token,
        },
      })
        .then((result) => {
          console.log(result);
          if (result.data.code === 1000) {
            if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
              ReactGA.event({
                category: "Contact",
                action: "Email",
                label: "Email from " + contactEmail,
              });
            }

            setMailSent(result.data.code);
            setMailError(false);
            handleReset();
          } else {
            setMailError(true);
          }
        })
        .catch((error) => setMailError(error.message));
    });
  }

  function handleReset() {
    setName("");
    setEmail("");
    setMessage("");
  }

  return (
    <div className="contactFormWrap relative w-full">
      <form
        action="#"
        className="grid gap-[1.875rem] md:grid-cols-2 grid-cols-1"
      >
        <div className="fieldBox w-full">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Name *
          </label>
          <input
            type="text"
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
            placeholder="Your Name"
            onChange={(e) => handleContactNameChange(e.target.value)}
            value={contactName}
          />
        </div>

        <div className="fieldBox w-full">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Email *
          </label>
          <input
            type="email"
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
            placeholder="Your Email"
            onChange={(e) => handleContactEmailChange(e.target.value)}
            value={contactEmail}
          />
        </div>

        <div className="fieldBox w-full col-span-2">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Message *
          </label>
          <textarea
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[8rem] md:h-[13.125rem] p-[1.125rem] text-white"
            placeholder="Write something ..."
            onChange={(e) => handleContactMessageChange(e.target.value)}
            value={contactMessage}
          />
        </div>

        <div className="fieldbtn mt-0 md:mt-[1.875rem] w-full col-span-2">
          <button
            className="bg-accent border-accent border-solid border-2 border-current text-dark text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1rem] py-[10px] hover:bg-accent hover:border-accent hover:text-white text-center inline-block"
            type="submit"
            onClick={handleFormSubmit}
          >
            Contact Me
          </button>
        </div>
        {mailSent && (
          <div
            className="w-full col-span-2 bg-green text-white rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700"
            role="alert"
          >
            <div className="flex p-4">
              <div className="ms-3">
                <p className="font-Poppins font-medium">{successMessage}</p>
              </div>
            </div>
          </div>
        )}
        {mailError && (
          <div
            className="w-full col-span-2 bg-red text-white rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700"
            role="alert"
          >
            <div className="flex p-4 w-full col-span-2">
              <div className="ms-3">
                <p className="font-Poppins font-medium">{errorMessage}</p>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
export default ContactForm;
